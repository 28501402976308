<template>
    <div class="banner">

<!--        <router-link to="/customer_center" tag="div" style="margin-top: 10px">-->
<!--            <a href="javascript:void(0)" target="_self">-->
<!--                <img alt="" src="../assets/images/banner/ptn/kakao.jpg" style="width: 100%">-->
<!--            </a>-->
<!--        </router-link>-->
        <div style="margin-top: 10px">
            <a href="https://gltv88.com" target="_blank">
                <img alt="" src="../assets/images/banner/ptn/live.jpg" style="width: 100%">
            </a>
        </div>
<!--        <div style="margin-top:10px">-->
<!--            <a href="https://t.me/" target="_blank">-->
<!--                <img alt="" src="../assets/images/banner/ptn/tel.jpg" style="width: 100%">-->
<!--            </a>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner{
        width: 100%;
        padding: 1px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>